<template>
  <div>
    <!-- 头部 -->
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goSelect" content="历史记录" style="margin:10px 0"> </el-page-header>
      </el-col>
    </el-row> -->
    <el-row class="select">
      <el-col :span="6" class="rows">
        <el-select v-model="typeName" filterable clearable placeholder="请选择设备类型">
          <el-option v-for="item in deviceType" :key="item.id" :label="item.name" :value="item.name">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="rows">
        <el-input v-model="deviceId" placeholder="请输入设备号"></el-input>
      </el-col>
      <el-col :span="6" class="rows">
        <el-input v-model="valueName" placeholder="请输入设备属性"></el-input>
      </el-col>
      <el-col :span="6" class="rows">
        <el-date-picker v-model="dateValue" type="datetimerange" :picker-options="pickerOptions" format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-col>
      <el-col :span="6" class="rows">
        <el-button icon="el-icon-search" @click="getAllByHandleStatus" type="primary">查询</el-button>
      </el-col>
      <!-- <el-col :span="6" class="rows">
        <el-button type="success" @click="export2Excel1">导出历史记录</el-button>
      </el-col>
      <el-col :span="6" class="rows">
        <el-button type="success" @click="export2Excel1">导出历史记录</el-button>
      </el-col> -->
      <el-col :span="6" class="rows" style="float:right;width:10%;margin-right:0px">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <!-- 表格 -->
    <el-table style="width: 100%" highlight-current-row :data="deviceHistoryValues" :header-cell-style="{ textAlign: 'center', background: '#eef1f6' }" :cell-style="{ textAlign: 'center' }" :stripe="true" :border="true" :show-overflow-tooltip="true">
      <el-table-column fixed type="index" :index="indexMethod" label="序号" width="70">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('设备号')" prop="deviceId" label="设备号">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('设备类型')" prop="typeName" label="设备类型">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('设备属性')" prop="valueName" label="设备属性">
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('值')" prop="value" label="值"> </el-table-column>
      <el-table-column v-if="tableTitle.includes('创建时间')" prop="createTime" label="创建时间"> </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination background @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next , jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { export_json_to_excel } from "../../introduce/Export2Excel";

import DeviceHistoryValue from "../../api/manageApi/DeviceHistoryValue";
import DeviceTypeManagement from "../../api/manageApi/DeviceTypeManagement";
import request from '../../../utils/request'
export default {
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '设备号',
      }, {
        id: 1,
        title: '设备类型',
      }, {
        id: 2,
        title: '设备属性',
      }, {
        id: 3,
        title: '值',
      }, {
        id: 4,
        title: '创建时间',
      }],
      tableTitle: ['设备号', '设备类型', '设备属性', '值', '创建时间'],

      dateValue: [new Date(new Date().toLocaleDateString()).getTime() - 1000 * 3600 * 24 * 7, new Date(new Date().toLocaleDateString()).getTime() + 1000 * 3600 * 24 - 1],
      // ((new Date()).getTime() - 3600 * 1000 * 24 * 7).valueOf(),((new Date()).getTime()).valueOf()
      deviceHistoryValues1: [],
      deviceHistoryValues: [], //实时值历史表
      deviceType: [], // 查询设备类型下拉框数据
      typeName: "", //设备类型
      deviceId: "", //设备号
      valueName: "", //设备属性
      total: 0, //总页数
      currentPage: 1, //第几页
      //日期选择控制
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start.valueOf(), end.valueOf()]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start.valueOf(), end.valueOf()]);
          }
        }]
      },
    };
  },

  mounted() {
    this.init();
    //获取所有设备类型
    DeviceTypeManagement.getAllDeviceType().then((resp) => {
      if (resp) {
        this.deviceType = resp.data.data.list;
      }
    });
  },
  methods: {
    goSelect() {
      this.$router.push("/Select");
    },
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备号')
      } else {
        this.tableTitle = value
      }
    },
    init() {
      console.log(this.valueName);
      //获取所有历史表中的数据(分页)
      let typeName = "";
      let deviceId = "";
      let valueName = "";
      let startTimeStamp = "";
      let endTimeStamp = "";
      if (this.typeName != "") {
        typeName = '&typeName=' + this.typeName
      }
      if (this.deviceId != "") {
        deviceId = '&deviceId=' + this.deviceId
      }
      if (this.valueName != "") {
        valueName = '&valueName=' + this.valueName
      }
      if (this.dateValue != null && this.dateValue != "") {
        startTimeStamp = '&startTimeStamp=' + this.dateValue[0]
        endTimeStamp = '&endTimeStamp=' + this.dateValue[1]
      }
      return request({
        url: 'device-history-value/getAll?number=' + this.currentPage + typeName + deviceId + valueName + startTimeStamp + endTimeStamp,
        method: 'get'
      })
        .then((resp) => {
          if (resp) {
            this.deviceHistoryValues = resp.data.data.list;
            this.total = resp.data.data.total;
          }
        });
    },

    indexMethod(index) {
      // 序号递增
      const currentPage = this.currentPage; // 当前页码
      const pageSize = 10; // 每页条数
      return index + 1 + (currentPage - 1) * pageSize; // 返回表格序号
    },
    handleCurrentChange(val) {
      // 第几页
      this.currentPage = val;
      this.init();
    },

    getAllByHandleStatus() {
      //条件查询
      this.currentPage = 1;
      this.init();
    },

    //导出excel
    export2Excel1() {
      require.ensure([], () => {
        const tHeader = ["设备号", "设备类型", "设备属性", "值", "创建时间"]; // 对应表格输出的中文title

        const filterVal = [
          "deviceId",
          "typeName",
          "valueName",
          "value",
          "createTime",
        ]; // 对应表格输出的数据

        const list = this.deviceHistoryValues1; // 表格data

        const data = this.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "历史记录"); // 对应下载文件的名字
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
};
</script>
<style scoped lang="scss">
.el-col-6 {
  width: auto;
}
.inPutStyle {
  width: 180px;
}
.select {
  margin-bottom: 10px;
  .rows {
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>